<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Lender List
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>Lender List</h3>
          <br />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <br />
          <v-data-table
            @click:row="handleClick"
            :headers="headers"
            :items="lenderList"
            :search="search"
            :items-per-page="-1"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "LenderRead",
  props: ["lenderId"],
  data() {
    return {
      search: "",

      headers: [
        {
          text: "Lender Name",
          align: "start",
          filterable: true,
          value: "lenderName",
        },
        {
          text: "Lender Type",
          value: "lenderType",
        },
        {
          text: "BC",
          value: "lendBC",
        },
        {
          text: "AB",
          value: "lendAB",
        },
        {
          text: "ON",
          value: "lendON",
        },
        {
          text: "Group Name",
          value: "group_name",
        },
      ],
      componentLoading: true,
      error: "",
      lenderList: [],
    };
  },
  methods: {
    handleClick(value) {
      this.$router.push(`/dashboard/lender/read/${value.id}`);
    },
  },
  mounted() {
    API()
      .get(`/lender/list`)
      .then((res) => {
        this.lenderList = res.data.recordset;
        this.componentLoading = false;
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>

<style>
</style>